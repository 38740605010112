<template>
  <div>
    <b-container
      class="min-vh-100 d-flex flex-column"
      style="background-color: var(--background-color)"
    >
      <b-row>
        <b-col cols="1" />
        <b-col>
          <h1 style="margin-top: 4.25rem">Nutzer einladen</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col cols="10">
          <h4 style="margin-top: 2rem; margin-bottom: 1rem">
            Bitte gebe die Nutzer-Daten an
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <b-input
            style="margin-bottom: 0.5rem"
            type="text"
            placeholder="Vorname"
            v-model="preName"
          />
        </b-col>
        <b-col>
          <b-input
            style="margin-bottom: 0.5rem"
            type="text"
            placeholder="Nachname"
            v-model="lastName"
          />
        </b-col>
        <b-col cols="1" />
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <b-input
            style="margin-bottom: 0.5rem"
            type="text"
            placeholder="E-Mail"
            v-model="email"
          />
        </b-col>
        <b-col cols="1" />
      </b-row>
      <b-row>
        <b-col cols="1" />
        <b-col>
          <b-button
            variant="primary"
            style="width: 100%; margin-top: 2rem"
            :disabled="!valid"
            @click="invite"
            >Einladen</b-button
          >
        </b-col>
        <b-col cols="1" />
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Start",
  data() {
    return {
      email: "",
      preName: "",
      lastName: "",
    };
  },
  methods: {
    async invite() {
      if (!this.valid) {
        this.toast("Fehler", "Ungültige Eingabe", "danger");
        return;
      }
      const token = await localStorage.getItem("token");
      this.axios
        .post(
          "auth/invite",
          {
            email: this.email,
            preName: this.preName,
            lastName: this.lastName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.status !== 200)
            return this.toast(
              "Es ist ein Fehler aufgetreten",
              "Ungültige Login-Daten",
              "danger"
            );
          this.$router.push("/");
        })
        .catch((/* err */) => {
          this.toast(
            "Account vergeben",
            "Bitte verwende eine andere E-Mail-Adresse",
            "danger"
          );
        });
    },
  },
  computed: {
    pwdValid() {
      return this.pwd.length > 6;
    },
    mailValid() {
      const regex = RegExp(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/);
      return regex.test(this.email);
    },
    valid() {
      return this.mailValid && this.email && this.preName && this.lastName;
    },
  },
};
</script>

<style scoped>
</style>
